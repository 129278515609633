import * as React from 'react';
import Container from '../components/Container';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage: React.FC = () => (
  <Layout>
    <Container>
      <SEO title="404: Not found" />
      <h1 style={{color: 'white', marginTop: '60px'}}>PAGE NOT FOUND</h1>
      <p style={{color: 'white', marginBottom: '60px'}}>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
);

export default NotFoundPage;
